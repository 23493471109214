import { createRouter, createWebHistory } from 'vue-router';
import LayoutComponent from '@/components/LayoutComponent.vue';
import FaceImproveView from '@/views/FaceImproveView.vue';
import ImproveImageQualityView from '@/views/ImproveImageQualityView.vue';
import ChangeClothes from '@/views/ChangeClothes.vue';
import LightChangeView from '@/views/LightChangeView.vue';

const routes = [
  {
    path: '/',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirect: '/face-improve'
      },
      {
        path: 'face-improve',
        name: 'FaceImproveView',
        component: FaceImproveView
      },
      {
        path: 'improve-image-quality',
        name: 'ImproveImageQualityView',
        component: ImproveImageQualityView
      },
      {
        path: '/change-clothes',
        name: 'ChangeClothes',
        component: ChangeClothes
      },
      {
        path: '/light-change',
        name: 'LightChange',
        component: LightChangeView
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
