<template>
  <header class="header">
    <div class="welcome">
    </div>
    <div class="actions">
      <button class="icon-button"><i class="icon-settings"></i></button>
      <button class="icon-button"><i class="icon-dark-mode"></i></button>
      <button class="icon-button"><i class="icon-notifications"></i></button>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderComponent'
}
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.welcome {
  font-size: 18px;
  font-weight: bold;
}

.actions {
  display: flex;
  align-items: center;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 15px;
  font-size: 20px;
}

.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
</style>