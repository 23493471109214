<template>
  <div class="change-clothes">
    <div class="image-container">
      <div class="image-box">
        <h3>1. Upload an Image</h3>
        <div 
          class="dropzone"
          @click="triggerFileInput('person')"
          @dragover.prevent
          @drop.prevent="handleDrop('person', $event)"
        >
          <img v-if="personImage" :src="personImage" alt="Person Image" />
          <div v-else class="placeholder">
            <i class="mdi mdi-upload"></i>
            <span>Click or Drag-n-Drop to Upload</span>
          </div>
        </div>
        <input 
          type="file" 
          ref="personFileInput"
          @change="handleFileSelect('person', $event)" 
          accept="image/*" 
          style="display: none;"
        />
        <div class="actions">
          <button class="delete-btn" @click.stop="clearImage('person')" v-if="personImage">
            <i class="mdi mdi-delete"></i> Clear
          </button>
        </div>
      </div>
      
      <div class="image-box">
        <h3>2. Upload a Garment Image</h3>
        <div 
          class="dropzone"
          @click="triggerFileInput('garment')"
          @dragover.prevent
          @drop.prevent="handleDrop('garment', $event)"
        >
          <img v-if="garmentImage" :src="garmentImage" alt="Garment Image" />
          <div v-else class="placeholder">
            <i class="mdi mdi-upload"></i>
            <span>Click or Drag-n-Drop to Upload</span>
          </div>
        </div>
        <input 
          type="file" 
          ref="garmentFileInput"
          @change="handleFileSelect('garment', $event)" 
          accept="image/*" 
          style="display: none;"
        />
        <div class="actions">
          <button class="delete-btn" @click.stop="clearImage('garment')" v-if="garmentImage">
            <i class="mdi mdi-delete"></i> Clear
          </button>
        </div>
      </div>

      <div class="image-box">
        <h3>3. Result</h3>
        <div class="dropzone result-zone">
          <img v-if="result" :src="result" alt="Try-On Result" />
          <div v-else-if="isLoading" class="placeholder loading">
            <div class="spinner"></div>
            <span>Generating image...</span>
          </div>
          <div v-else-if="error" class="placeholder error">
            <span>{{ error }}</span>
          </div>
          <div v-else class="placeholder">
            <span>Generated image will appear here</span>
          </div>
        </div>
        <div class="actions" v-if="result">
          <button class="download-btn" @click="downloadImage">
            <i class="mdi mdi-download"></i> Download
          </button>
        </div>
      </div>
    </div>

    <button @click="generateTryOn" :disabled="!personImage || !garmentImage || isLoading" class="generate-btn">
      {{ isLoading ? 'Generating...may take up to 1 minute' : 'Try on clothes' }}
    </button>

    <div class="tip-container">
      <p class="tip-text">
        <i class="mdi mdi-information"></i>
        Important: Use images with a 3:4 ratio for best results. 
        <a href="https://www.birme.net/?target_height=682" target="_blank" rel="noopener noreferrer" class="tip-link">
          Resize your images here
        </a>
      </p>
    </div>

    <p v-if="error" class="error-message">{{ error }}</p>
  </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';

export default {
  name: 'ChangeClothes',
  setup() {
    const personImage = ref(null);
    const garmentImage = ref(null);
    const result = ref(null);
    const isLoading = ref(false);
    const error = ref(null);
    const personFileInput = ref(null);
    const garmentFileInput = ref(null);

    const triggerFileInput = (type) => {
      if (type === 'person') {
        personFileInput.value.click();
      } else {
        garmentFileInput.value.click();
      }
    };

    const handleFileSelect = (type, event) => {
      const file = event.target.files[0];
      handleFile(type, file);
    };

    const handleDrop = (type, event) => {
      const file = event.dataTransfer.files[0];
      handleFile(type, file);
    };

    const handleFile = (type, file) => {
      if (file && file.type.startsWith('image/')) {
        if (file.size > 5 * 1024 * 1024) { // 5MB limit
          alert('File size should not exceed 5MB');
          return;
        }
        const reader = new FileReader();
        reader.onload = (e) => {
          if (type === 'person') {
            personImage.value = e.target.result;
          } else {
            garmentImage.value = e.target.result;
          }
        };
        reader.readAsDataURL(file);
      }
    };

    const clearImage = (type) => {
      if (type === 'person') {
        personImage.value = null;
      } else {
        garmentImage.value = null;
      }
    };

    const generateTryOn = async () => {
      if (!personImage.value || !garmentImage.value) {
        alert('Please upload both a person image and a garment image.');
        return;
      }

      isLoading.value = true;
      result.value = null;
      error.value = null;

      try {
        const apiUrl = process.env.VUE_APP_API_URL || 'https://new.app.profilebakery.com';
        const response = await axios.post(`${apiUrl}/api/changeClothes`, {
          personImageBase64: personImage.value.split(',')[1],
          garmentImageBase64: garmentImage.value.split(',')[1]
        }, {
          responseType: 'arraybuffer',
          timeout: 70000 // 70 seconds timeout (slightly longer than server-side)
        });

        const blob = new Blob([response.data], { type: 'image/jpeg' });
        result.value = URL.createObjectURL(blob);
      } catch (err) {
        console.error('Error generating try-on:', err);
        if (err.response) {
          if (err.response.status === 504) {
            error.value = 'The request timed out. The image processing is taking longer than expected. Please try again or use smaller images.';
          } else {
            error.value = `Server error: ${new TextDecoder().decode(err.response.data) || 'Unknown error'}`;
          }
        } else if (err.request) {
          error.value = 'Network error. Please check your internet connection.';
        } else {
          error.value = 'An unexpected error occurred. Please try again.';
        }
      } finally {
        isLoading.value = false;
      }
    };

    const downloadImage = () => {
      const link = document.createElement('a');
      link.href = result.value;
      link.download = 'try_on_result.jpg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    return {
      personImage,
      garmentImage,
      result,
      isLoading,
      error,
      personFileInput,
      garmentFileInput,
      triggerFileInput,
      handleFileSelect,
      handleDrop,
      clearImage,
      generateTryOn,
      downloadImage,
    };
  },
};
</script>

<style scoped>
.change-clothes {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.image-container {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
}

.image-box {
  flex: 1;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  max-width: calc(33.333% - 0.67rem);
}

h3 {
  margin-bottom: 1rem;
  color: #333;
  text-align: center;
}

.dropzone {
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.result-zone {
  cursor: default;
}

.dropzone img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.placeholder {
  color: #888;
}

.placeholder i {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.actions {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.delete-btn {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.generate-btn {
  display: block;
  width: 100%;
  padding: 0.75rem;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

.generate-btn:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.error-message {
  color: #dc3545;
  margin-top: 1rem;
  text-align: center;
}

.placeholder.error {
  color: #dc3545;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .image-container {
    flex-wrap: wrap;
  }

  .image-box {
    flex: 1 1 calc(50% - 0.5rem);
    max-width: calc(50% - 0.5rem);
  }
}

@media (max-width: 768px) {
  .image-container {
    flex-direction: column;
  }

  .image-box {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .dropzone {
    height: 150px;
  }
}

@media (max-width: 480px) {
  .change-clothes {
    padding: 0.5rem;
  }

  h3 {
    font-size: 1.2rem;
  }

  .dropzone {
    height: 120px;
  }

  .generate-btn {
    padding: 0.5rem;
    font-size: 0.9rem;
  }
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.download-btn {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin-top: 1rem;
}

.download-btn:hover {
  background-color: #0056b3;
}

.tip-container {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #e9f5ff;
  border-radius: 8px;
  border-left: 4px solid #007bff;
}

.tip-text {
  color: #333;
  font-size: 0.9rem;
  line-height: 1.5;
  margin: 0;
}

.icon-info-circle {
  color: #007bff;
  margin-right: 0.5rem;
}

.tip-link {
  color: #007bff;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
}

.tip-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

@media (max-width: 480px) {
  .tip-container {
    padding: 0.75rem;
  }

  .tip-text {
    font-size: 0.8rem;
  }
}
</style>
