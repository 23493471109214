<template>
  <div class="face-improve">
    <div class="image-container">
      <div class="image-box">
        <h3>1. Good image of your face</h3>
        <div 
          class="dropzone"
          @click="triggerFileInput('input')"
          @dragover.prevent
          @drop.prevent="handleDrop('input', $event)"
        >
          <img v-if="inputFace" :src="inputFace" alt="Input Face" />
          <div v-else class="placeholder">
            <i class="mdi mdi-upload"></i>
            <span>Click or Drag-n-Drop</span>
          </div>
        </div>
        <input 
          type="file" 
          ref="inputFileInput"
          @change="handleFileSelect('input', $event)" 
          accept="image/*" 
          style="display: none;"
        />
        <div class="actions">
          <button class="delete-btn" @click.stop="clearImage('input')" v-if="inputFace">
            <i class="mdi mdi-delete"></i> Clear
          </button>
        </div>
      </div>
      
      <div class="image-box">
        <h3>2. Our Image you don't like</h3>
        <div 
          class="dropzone"
          @click="triggerFileInput('target')"
          @dragover.prevent
          @drop.prevent="handleDrop('target', $event)"
        >
          <img v-if="targetFace" :src="targetFace" alt="Target Face" />
          <div v-else class="placeholder">
            <i class="icon-upload"></i>
            <span>Click or Drag-n-Drop</span>
          </div>
        </div>
        <input 
          type="file" 
          ref="targetFileInput"
          @change="handleFileSelect('target', $event)" 
          accept="image/*" 
          style="display: none;"
        />
        <div class="actions">
          <button class="delete-btn" @click.stop="clearImage('target')" v-if="targetFace">
            <i class="icon-trash"></i> Clear
          </button>
        </div>
      </div>

      <div class="image-box">
        <h3>3. Result</h3>
        <div class="dropzone result-zone">
          <img v-if="resultImage" :src="resultImage" alt="Result Image" />
          <div v-else-if="isLoading" class="placeholder loading">
            <div class="spinner"></div>
            <span>Generating image...</span>
          </div>
          <div v-else-if="error" class="placeholder error">
            <span>{{ error }}</span>
          </div>
          <div v-else class="placeholder">
            <span>Generated image will appear here</span>
          </div>
        </div>
        <div class="actions" v-if="resultImage">
          <button class="download-btn" @click="downloadImage">
            <i class="icon-download"></i> Download
          </button>
        </div>
      </div>
    </div>

    <button @click="generateImage" :disabled="!inputFace || !targetFace || isLoading" class="generate-btn">
      {{ isLoading ? 'Generating...' : 'Generate' }}
    </button>

    <p v-if="error" class="error-message">{{ error }}</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'FaceImproveView',
  data() {
    return {
      inputFace: null,
      targetFace: null,
      resultImage: null,
      isLoading: false,
      error: null
    }
  },
  methods: {
    triggerFileInput(type) {
      this.$refs[`${type}FileInput`].click()
    },
    handleFileSelect(type, event) {
      const file = event.target.files[0]
      this.handleFile(type, file)
    },
    handleDrop(type, event) {
      const file = event.dataTransfer.files[0]
      this.handleFile(type, file)
    },
    handleFile(type, file) {
      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this[`${type}Face`] = e.target.result
        }
        reader.readAsDataURL(file)
      }
    },
    clearImage(type) {
      this[`${type}Face`] = null
    },
    async generateImage() {
      if (!this.inputFace || !this.targetFace) {
        alert('Please select both input and target faces.')
        return
      }

      this.isLoading = true
      this.resultImage = null
      this.error = null

      try {
        const inputFaceBase64 = this.getBase64FromDataUrl(this.inputFace)
        const targetFaceBase64 = this.getBase64FromDataUrl(this.targetFace)

        const apiUrl = process.env.VUE_APP_API_URL || 'https://new.app.profilebakery.com';
        const response = await axios.post(`${apiUrl}/api/faceswap`, {
          source_img: inputFaceBase64,
          target_img: targetFaceBase64
        });

        console.log('API Response:', response.data); // Log the response for debugging

        if (response.data && typeof response.data === 'string') {
          // If the response is already a base64 string
          this.resultImage = `data:image/jpeg;base64,${response.data}`;
        } else if (response.data && response.data.image) {
          // If the response is an object with an 'image' property containing the base64 string
          this.resultImage = `data:image/jpeg;base64,${response.data.image}`;
        } else {
          throw new Error('Unexpected API response format');
        }
      } catch (error) {
        console.error('Error:', error)
        this.error = 'An error occurred while generating the image. Please try again.'
      } finally {
        this.isLoading = false
      }
    },
    getBase64FromDataUrl(dataUrl) {
      return dataUrl.split(',')[1]
    },
    downloadImage() {
      const link = document.createElement('a');
      link.href = this.resultImage;
      link.download = 'face_swap_result.jpg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
</script>

<style scoped>
.face-improve {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.image-container {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
}

.image-box {
  flex: 1;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h3 {
  margin-bottom: 1rem;
  color: #333;
  text-align: center;
}

.dropzone {
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.result-zone {
  cursor: default;
}

.dropzone img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.placeholder {
  color: #888;
}

.placeholder i {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.actions {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.delete-btn {
  background-color: #ff4757;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 0.75rem 1.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  font-weight: 600;
  font-size: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.delete-btn:hover {
  background-color: #ff6b6b;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.delete-btn:active {
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.delete-btn i {
  margin-right: 0.75rem;
  font-size: 1.2rem;
}

.file-info {
  font-size: 0.8rem;
  color: #666;
  text-align: center;
  margin-top: 0.5rem;
}

.generate-btn {
  display: block;
  width: 100%;
  padding: 0.75rem;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

.generate-btn:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.error-message {
  color: #dc3545;
  margin-top: 1rem;
  text-align: center;
}

.placeholder.error {
  color: #dc3545;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .image-container {
    flex-direction: column;
  }

  .image-box {
    margin-bottom: 1rem;
  }
}

@media (max-width: 768px) {
  .image-container {
    flex-direction: column;
  }

  .image-box {
    flex: 1 1 100%;
  }

  .dropzone {
    height: 150px;
  }
}

@media (max-width: 480px) {
  .face-improve {
    padding: 0.5rem;
  }

  h3 {
    font-size: 1.2rem;
  }

  .dropzone {
    height: 120px;
  }

  .file-info {
    font-size: 0.7rem;
  }

  .generate-btn {
    padding: 0.5rem;
    font-size: 0.9rem;
  }
}

.download-btn {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin-top: 1rem;
}

.download-btn:hover {
  background-color: #0056b3;
}
</style>