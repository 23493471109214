<template>
  <div class="sidebar">
    <div class="logo">
      <h1>Image Enhancer</h1>
    </div>
    <nav>
      <router-link to="/face-improve" class="nav-item" active-class="active">
        <i class="icon-face-improve"></i>Face Improve
      </router-link>
      <router-link to="/improve-image-quality" class="nav-item" active-class="active">
        <i class="icon-image-quality"></i>Improve Image Quality
      </router-link>
      <router-link to="/change-clothes" class="nav-item change-clothes-link" active-class="active">
        <i class="icon-change-clothes"></i>Change Clothes
      </router-link>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'SidebarComponent',
}
</script>

<style scoped>
.sidebar {
  width: 250px;
  background-color: #25293c;
  color: white;
  padding: 20px;
  height: 100vh;
}

.logo {
  margin-bottom: 30px;
}

.logo h1 {
  font-size: 24px;
  font-weight: bold;
  color: white;
  margin: 0;
}

.nav-item {
  display: block;
  padding: 10px;
  color: white;
  text-decoration: none;
  margin-bottom: 10px;
}

.nav-item.active {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}

.nav-item i {
  margin-right: 10px;
}

.icon-change-clothes::before {
  content: "\1F457"; 
}

.icon-image-quality::before {
  content: "\1F4F7"; /* 📷 Camera emoji */
}

.icon-face-improve::before {
  content: "\1F9D1"; /* 🧑 Person emoji */
}

/* Hide the Change Clothes link */
.change-clothes-link {
  display: none;
}
</style>