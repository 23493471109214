<template>
  <div class="light-change">
    <div class="image-container">
      <!-- Input Image Column -->
      <div class="column">
        <h3>Input Image</h3>
        <div 
          class="dropzone"
          @click="triggerFileInput"
          @dragover.prevent
          @drop.prevent="handleDrop"
        >
          <img v-if="inputImage" :src="inputImage" alt="Input Image" />
          <div v-else class="placeholder">
            <i class="mdi mdi-upload"></i>
            <span>Click or Drag-n-Drop to Upload</span>
          </div>
        </div>
        <input type="file" ref="fileInput" @change="onFileChange" accept="image/*" style="display: none;" />
        <div class="actions">
          <button class="delete-btn" @click.stop="clearImage" v-if="inputImage">
            <i class="mdi mdi-delete"></i> Clear
          </button>
        </div>
      </div>
      
      <!-- Prompt Column -->
      <div class="column">
        <h3>Prompt</h3>
        <div class="input-group">
          <input type="text" id="prompt" v-model="prompt" placeholder="Enter your prompt here">
        </div>
        
        <!-- Text Snippets -->
        <div class="text-snippets">
          <button 
            v-for="snippet in textSnippets" 
            :key="snippet" 
            @click="addSnippet(snippet)" 
            class="snippet-btn"
          >
            {{ snippet }}
          </button>
        </div>

        <button @click="generateImage" :disabled="!inputImage || isLoading" class="generate-btn">
          {{ isLoading ? 'Generating...takes about 30s' : 'Generate' }}
        </button>
      </div>
      
      <!-- Result Image Column -->
      <div class="column">
        <h3>Result Image</h3>
        <div class="dropzone result-zone">
          <img v-if="resultImage" :src="resultImage" alt="Result Image" />
          <div v-else-if="isLoading" class="placeholder loading">
            <div class="spinner"></div>
            <span>Generating image...</span>
          </div>
          <div v-else-if="error" class="placeholder error">
            <span>{{ error }}</span>
          </div>
          <div v-else class="placeholder">
            <span>Generated image will appear here</span>
          </div>
        </div>
        <div class="actions" v-if="resultImage">
          <button class="download-btn" @click="downloadImage">
            <i class="mdi mdi-download"></i> Download
          </button>
        </div>
      </div>
    </div>
  
    <p v-if="error" class="error-message">{{ error }}</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'LightChangeView',
  data() {
    return {
      inputImage: null,
      resultImage: null,
      base64Image: null,
      prompt: '',
      error: null,
      isLoading: false,
      textSnippets: [
        "Blurry office background",
        "blurry new york background",
        "city neon light",
        "soft studio light"
      ]
    };
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    onFileChange(event) {
      const file = event.target.files[0];
      this.handleFile(file);
    },
    handleDrop(event) {
      const file = event.dataTransfer.files[0];
      this.handleFile(file);
    },
    async handleFile(file) {
      if (file && file.type.startsWith('image/')) {
        this.inputImage = URL.createObjectURL(file);
        try {
          this.base64Image = await this.convertToBase64(file);
        } catch (error) {
          console.error('Error converting image to base64:', error);
          this.error = 'Error processing the image. Please try again.';
        }
      }
    },
    clearImage() {
      this.inputImage = null;
      this.resultImage = null;
      this.base64Image = null;
      this.error = null;
    },
    convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          // Remove the "data:image/..." prefix
          const base64String = reader.result.split(',')[1];
          resolve(base64String);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    async generateImage() {
      if (!this.inputImage || !this.prompt) {
        alert('Please upload an image and enter a prompt.');
        return;
      }
  
      this.isLoading = true;
      this.resultImage = null;
      this.error = null;
  
      try {
        const apiUrl = process.env.VUE_APP_API_URL || 'https://new.app.profilebakery.com';
        const response = await axios.post(`${apiUrl}/api/lightChange`, {
          subject_image: this.base64Image,
          prompt: this.prompt
        });

        if (response.data && response.data.output && response.data.output.length > 0) {
          this.resultImage = response.data.output[0];
        } else {
          throw new Error('Unexpected API response format');
        }
      } catch (error) {
        console.error('Error:', error.response?.data || error.message);
        this.error = error.response?.data?.error || error.message || 'An error occurred while generating the image. Please try again.';
      } finally {
        this.isLoading = false;
      }
    },
    downloadImage() {
      const link = document.createElement('a');
      link.href = this.resultImage;
      link.download = 'light_changed_image.jpg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    addSnippet(snippet) {
      if (this.prompt) {
        this.prompt += ', ' + snippet;
      } else {
        this.prompt = snippet;
      }
    }
  }
};
</script>

<style scoped>
.light-change {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.image-container {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
}

.column {
  flex: 1;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

h3 {
  margin-bottom: 1rem;
  color: #333;
  text-align: center;
}

.dropzone {
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.result-zone {
  cursor: default;
}

.dropzone img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.placeholder {
  color: #888;
}

.placeholder i {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.actions {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.delete-btn, .download-btn {
  background-color: #ff4757;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.download-btn {
  background-color: #007bff;
}

.delete-btn:hover, .download-btn:hover {
  opacity: 0.8;
}

.input-group {
  margin-bottom: 1rem;
}

.input-group input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.text-snippets {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.snippet-btn {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 16px;
  padding: 0.25rem 0.75rem;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.snippet-btn:hover {
  background-color: #e0e0e0;
}

.generate-btn {
  width: 100%;
  padding: 0.75rem;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: auto;
}

.generate-btn:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.error-message {
  color: #dc3545;
  margin-top: 1rem;
  text-align: center;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .image-container {
    flex-direction: column;
  }

  .column {
    margin-bottom: 1rem;
  }

  .dropzone {
    height: 150px;
  }
}

@media (max-width: 480px) {
  .light-change {
    padding: 0.5rem;
  }

  h3 {
    font-size: 1.2rem;
  }

  .dropzone {
    height: 120px;
  }

  .generate-btn {
    padding: 0.5rem;
    font-size: 0.9rem;
  }
}
</style>