<template>
  <div class="image-improve">
    <div class="image-container">
      <!-- Input Image Column -->
      <div class="image-box">
        <h3>Input Image</h3>
        <div 
          class="dropzone"
          @click="triggerFileInput"
          @dragover.prevent
          @drop.prevent="handleDrop"
        >
          <img v-if="inputImage" :src="inputImage" alt="Input Image" />
          <div v-else class="placeholder">
            <i class="mdi mdi-upload"></i>
            <span>Click or Drag-n-Drop to Upload</span>
          </div>
        </div>
        <input type="file" ref="fileInput" @change="onFileChange" style="display: none;" />
        <div class="actions">
          <button class="delete-btn" @click.stop="clearImage" v-if="inputImage">
            <i class="mdi mdi-delete"></i> Clear
          </button>
        </div>
      </div>
      
      <!-- Upscaled Image Column -->
      <div class="image-box">
        <h3>Upscaled Image</h3>
        <div class="dropzone result-zone">
          <img v-if="outputImage" :src="outputImage" alt="Upscaled Image" />
          <div v-else-if="isLoading" class="placeholder loading">
            <div class="spinner"></div>
            <span>Generating image...</span>
          </div>
          <div v-else-if="error" class="placeholder error">
            <span>{{ error }}</span>
          </div>
          <div v-else class="placeholder">
            <span>Generated image will appear here</span>
          </div>
        </div>
        <div class="actions" v-if="outputImage">
          <button class="download-btn" @click="downloadImage">
            <i class="mdi mdi-download"></i> Download
          </button>
        </div>
      </div>
    </div>
    
    <button @click="generateImage" :disabled="!inputImage || isLoading" class="generate-btn">
      {{ isLoading ? 'Generating...' : 'Generate' }}
    </button>

    <p v-if="error" class="error-message">{{ error }}</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      inputImage: null,
      outputImage: null,
      base64Image: null,
      error: null,
      isLoading: false,
      apiKey: process.env.VUE_APP_SEGMIND_API_KEY // Ensure this matches your environment variable
    };
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    onFileChange(event) {
      const file = event.target.files[0];
      this.inputImage = URL.createObjectURL(file);
      this.convertToBase64(file);
    },
    handleDrop(event) {
      const file = event.dataTransfer.files[0];
      this.inputImage = URL.createObjectURL(file);
      this.convertToBase64(file);
    },
    clearImage() {
      this.inputImage = null;
      this.outputImage = null;
      this.error = null;
    },
    async convertToBase64(file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.base64Image = reader.result.split(',')[1];
      };
      reader.readAsDataURL(file);
    },
    async generateImage() {
      if (!this.inputImage) {
        alert('Please upload an image.');
        return;
      }

      this.isLoading = true;
      this.outputImage = null;
      this.error = null;

      try {
        const apiUrl = 'https://new.app.profilebakery.com/api/improveImageQuality';
        const payload = {
          image: this.base64Image,
          scale: 2
        };

        const response = await axios.post(apiUrl, payload, {
          responseType: 'arraybuffer',
          timeout: 300000 // 5 minutes timeout
        });

        // Create a blob from the arraybuffer response
        const blob = new Blob([response.data], { type: 'image/jpeg' });
        this.outputImage = URL.createObjectURL(blob);
      } catch (error) {
        console.error('Error:', error);
        if (error.response) {
          console.error('Error status:', error.response.status);
          if (error.response.status === 504) {
            this.error = 'The request timed out. The image might be too large or the server is busy. Please try again later.';
          } else {
            this.error = `An error occurred (Status ${error.response.status}). Please try again.`;
          }
        } else if (error.request) {
          this.error = 'No response received from the server. Please check your internet connection and try again.';
        } else {
          this.error = 'An unexpected error occurred. Please try again.';
        }
      } finally {
        this.isLoading = false;
      }
    },
    downloadImage() {
      const link = document.createElement('a');
      link.href = this.outputImage;
      link.download = 'improved_image.jpg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
</script>

<style scoped>
.image-improve {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
}

.image-box {
  flex: 1 1 300px;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
}

h3 {
  margin-bottom: 1rem;
  color: #333;
  text-align: center;
}

.dropzone {
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.result-zone {
  cursor: default;
}

.dropzone img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.placeholder {
  color: #888;
}

.placeholder i {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.actions {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.delete-btn {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.file-info {
  font-size: 0.8rem;
  color: #666;
  text-align: center;
  margin-top: 0.5rem;
}

.generate-btn {
  display: block;
  width: 100%;
  padding: 0.75rem;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

.generate-btn:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.error-message {
  color: #dc3545;
  margin-top: 1rem;
  text-align: center;
}

.placeholder.error {
  color: #dc3545;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .image-container {
    flex-direction: column;
  }

  .image-box {
    flex: 1 1 100%;
  }

  .dropzone {
    height: 150px;
  }
}

@media (max-width: 480px) {
  .image-improve {
    padding: 0.5rem;
  }

  h3 {
    font-size: 1.2rem;
  }

  .dropzone {
    height: 120px;
  }

  .file-info {
    font-size: 0.7rem;
  }

  .generate-btn {
    padding: 0.5rem;
    font-size: 0.9rem;
  }
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.download-btn {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin-top: 1rem;
}

.download-btn:hover {
  background-color: #0056b3;
}
</style>
