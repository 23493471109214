<template>
    <div class="app-layout">
      <SidebarComponent />
      <div class="main-content">
        <HeaderComponent />
        <div class="content-area">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import SidebarComponent from './SidebarComponent.vue';
  import HeaderComponent from './HeaderComponent.vue';
  
  export default {
    name: 'LayoutComponent',
    components: {
      SidebarComponent,
      HeaderComponent,
    },
  }
  </script>
  
  <style scoped>
  .app-layout {
    display: flex;
    height: 100vh;
  }
  
  .main-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  
  .content-area {
    flex-grow: 1;
    padding: 20px;
    background-color: #f8f9fa;
    overflow-y: auto;
  }
  </style>